import React, { useState, useEffect } from 'react';
import { ReactComponent as Like } from '../images/like.svg';
import { ReactComponent as Liked } from '../images/liked.svg';
import { ReactComponent as BrandIcon } from '../images/logo.svg';

const TypewriterMessage = ({ content, onTypingComplete, setCompleted }) => {
  const [displayedContent, setDisplayedContent] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [htmlContent, setHtmlContent] = useState([]);

  useEffect(() => {
    const parseHtmlContent = (html) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      const elements = [];
      
      const traverse = (node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          elements.push({ type: 'text', content: node.textContent });
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          const tag = node.tagName.toLowerCase();
          const children = Array.from(node.childNodes).map(traverse).flat();
          elements.push({ type: 'element', tag, children });
        }
        return elements;
      };
      
      traverse(doc.body);
      return elements;
    };

    setHtmlContent(parseHtmlContent(content));
  }, [content]);

  useEffect(() => {
    if (currentIndex < content.length) {
      const timer = setTimeout(() => {
        setDisplayedContent(content.slice(0, currentIndex + 1));
        setCurrentIndex(prev => prev + 1);
      }, 2.8);

      return () => clearTimeout(timer);
    } else if (currentIndex === content.length) {
      // Animation is complete, call the callback
      onTypingComplete && onTypingComplete();
    }
  }, [currentIndex, content, onTypingComplete]);

  return (
    <div 
      className="transition-opacity duration-300"
      style={{ opacity: displayedContent.length > 0 ? 1 : 0 }}
      dangerouslySetInnerHTML={{ __html: displayedContent }}
    />
  );
};

const AnimatedMessage = ({ 
  message, 
  showAvatar, 
  image, 
  index, 
  animate, 
  messageReactions, 
  handleReaction, 
  promptLoading, 
  errorResponse,
  onMessageTypingComplete,
  completed,
  setCompleted,
  length,
  historyChats,
  errorResponseMessages
}) => {
  setCompleted(false)
  return (
    <div className={!completed && !errorResponseMessages && length - 1 === index && index !== 2 ? historyChats ? 'w-full' : 'h-[calc(100dvh-270px)] md:h-[calc(100dvh-240px)] w-full' : 'w-full'}>
      <div className={`pl-3 pt-3 pr-5 min-h-[55px] transition-all duration-300 pb-4 bg-white rounded-lg md:rounded-xl border border-slate-200 justify-start items-start gap-3 flex-col w-full`}>
        <div className='flex gap-3 w-full'>
          <div className={`min-w-6 max-w-6 h-auto ${message.message_text === 'loading' ? 'mt-[2px]' : 'mt-[4px]'}`}>
            {image ? (
              <img className='max-w-6 h-auto max-h-[31px] rounded-[8px] object-cover' src={image} alt="logo" />
            ) : (
              <BrandIcon className='w-6' />
            )}
          </div>
          <div className='flex flex-col w-full gap-5 mt-1'>
            {message.message_text === 'loading' ? (
              promptLoading && (
                <div className='flex items-center gap-2 text-sm md:text-base mt-[3px] md:mt-1'>
                  <div className="balls">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              )
            ) : message.message_text === 'error' ? (
              <p className='text-red-700 pt-1'>
                {errorResponse ? errorResponse : 'There was an error loading response'}!
              </p>
            ) : (
              <>
                <div className='agent'>
                  {animate - 1 === index && !historyChats ? (
                    <TypewriterMessage 
                      content={message.message_text} 
                      setCompleted={setCompleted}
                      onTypingComplete={() => onMessageTypingComplete && onMessageTypingComplete(index)}
                    />
                  ) : (
                    <div className='agent' dangerouslySetInnerHTML={{ __html: message.message_text }} />
                  )}
                </div>
                <div className="h-9 px-2.5 w-fit py-[5px] bg-slate-100 rounded-[10px] mb-2 mt-[-4px] justify-start items-center gap-3.5 inline-flex">
                  <div className="text-gray-800 text-[13px] font-normal leading-relaxed">
                    Was this response helpful?
                  </div>
                  <div className="justify-start items-center gap-[9.60px] flex">
                    {messageReactions[index]?.type !== 'dislike' && (
                      <button className='cursor-pointer' onClick={() => handleReaction(index, 'like')}>
                        {messageReactions[index]?.type === 'like' ? (
                          <Liked className='w-4 h-4 cursor-pointer' />
                        ) : (
                          <Like className='w-4 h-4 cursor-pointer' />
                        )}
                      </button>
                    )}
                    {messageReactions[index]?.type !== 'like' && (
                      <button className='cursor-pointer' onClick={() => handleReaction(index, 'dislike')}>
                        {messageReactions[index]?.type === 'dislike' ? (
                          <Liked className='w-4 h-4 rotate-180 cursor-pointer' />
                        ) : (
                          <Like className='w-4 h-4 rotate-180 cursor-pointer' />
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnimatedMessage;