import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as Tick } from '../images/tick.svg';
import { ReactComponent as User } from '../images/user.svg';
import { ReactComponent as Phone } from '../images/phone.svg';

import AnimatedMessage from './AnimatedMessage';
import ConnectForm from './ConnectForm';
import { Loader } from "lucide-react"
import Cookies from 'js-cookie';

const useQuery = () => {
    const location = useLocation();
    return new URLSearchParams(location.search);
};

const Conversation = ({formData, historyChats, errorResponseMessages, setCompleted, completed, setShowFormLast, resultQuestion, showForm, questionAi, handleMessage, id, promptLoading, setShowForm, aiAnswer, showAvatar, conversationId, randomId, errorResponse, prompt, image, setMessages, messages}) => {
    const questions = [
        'Learn more about the solutions we provide?',
        'Learn more about the solutions we provide?',
        'Learn more about the solutions we provide?'
    ];
    const query = useQuery();
    const scrollConversations = query.get('conversations');
    const [connectNow, setConnectNow] = useState(1);
    const messagesEndRef = useRef(null);
    const [formatMessage, setFormateMessage] = useState(messages); 
    const [animate, setAnimate] = useState(0);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    const customerId = Cookies.get(`customerId-${id}`);
    if(randomId && !customerId) {
        Cookies.set(`customerId-${id}`, randomId, { expires: 0.5, sameSite: 'None', secure: true });
    }
    useEffect(() => {
        const customerId = Cookies.get(`customerId-${id}`);
        if(randomId && !customerId) {
            Cookies.set(`customerId-${id}`, randomId, { expires: 0.5, sameSite: 'None', secure: true });
        }
    }, [messages]);
    useEffect(() => {
        const filteredMessages = messages.filter(msg => !(msg.user_type === 'AI' && msg.message_text === 'loading' && !promptLoading));
        setFormateMessage(filteredMessages);
        setAnimate(filteredMessages.length)
    }, [messages]);
    useEffect(() => {
        if (prompt) {
            setMessages(prevMessages => [
                ...prevMessages,
                { user_type: 'USER', message_text: prompt }
            ]);
        }
    }, [prompt]);
    useEffect(() => {
        if (promptLoading) {
            setMessages(prevMessages => [
                ...prevMessages,
                { user_type: 'AI', message_text: 'loading' }
            ]);
        }
    }, [promptLoading]);
    useEffect(() => {
        if (errorResponseMessages) {
            setMessages(prevMessages => 
                prevMessages.map((msg, index) => 
                    index === prevMessages.length - 1 && msg.user_type === 'AI' && msg.message_text === 'loading'
                        ? { ...msg, message_text: 'error' }
                        : msg
                )
            );
        }
    }, [errorResponseMessages]);
    useEffect(() => {
        if (!scrollConversations || scrollConversations !== 'true') {
            if (promptLoading) {
                setTimeout(() => {
                    scrollToBottom();
                }, 10)
            }
        }
    }, [messages, promptLoading, errorResponse, formatMessage]);
    const [messageReactions, setMessageReactions] = useState({});
    // Add this function to handle reactions
    const handleReaction = (messageIndex, reactionType) => {
        setMessageReactions(prev => {
            const currentReactions = { ...prev };  
            if (currentReactions[messageIndex]?.type === reactionType) {
                delete currentReactions[messageIndex];
            } else {
                currentReactions[messageIndex] = {
                    type: reactionType,
                    timestamp: new Date().toISOString()
                };
            }
            return currentReactions;
        });
    };
    const onMessageTypingComplete = () => {
        setCompleted(true)
    }
    return (
        <div className={`w-[690px] max-w-full p-5 px-5 md:max-h-[calc(100dvh-80px)] overflow-y-scroll visible-area show flex flex-col gap-2 ${formatMessage.length > 0 ? '' : 'h-[100dvh] items-center justify-center'}`} >
            {formatMessage.length > 0 ? formatMessage.map((message, index) => (
                <div key={index} className='flex'>
                    {message.user_type === 'USER' ? (
                        <div className="min-h-[50px] p-3 bg-slate-100 rounded-xl w-fit border border-slate-200 mb-3 mt-3 justify-start items-center gap-3 flex max-w-full">
                            <User className='min-w-6 h-6' />
                            <div className="text-slate-900 text-sm font-normal leading-relaxed">{message.message_text}</div>
                        </div>
                    ) : (
                        <AnimatedMessage
                            setCompleted={setCompleted}    
                            completed={completed}
                            onMessageTypingComplete={onMessageTypingComplete}
                            animate={animate}
                            message={message}
                            showAvatar={showAvatar}
                            image={image}
                            index={index}
                            length={formatMessage.length}
                            messageReactions={messageReactions}
                            handleReaction={handleReaction}
                            promptLoading={promptLoading}
                            errorResponse={errorResponse}
                            historyChats={historyChats}
                            errorResponseMessages={errorResponseMessages}
                        />
                    )}
                </div>
            )) : 
                <div className="balls">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            }
            <div ref={messagesEndRef} />
            {formatMessage.length > 0 && showForm && (historyChats ? historyChats : (errorResponseMessages || completed)) &&
                <div className='py-4 fade-up-div relative z-[1]'>
                    {connectNow === 3 ?
                        <div className="h-12 px-4 py-3.5 bg-[#8451d4]/10 rounded-lg border border-black/10 justify-start items-center gap-2.5 inline-flex w-full">
                            <div className="justify-start items-center gap-3 flex w-full">
                                <Tick />
                                <p className="text-slate-800 text-sm font-normal leading-tight">Thank you for submitting your feedback</p>
                                <button className='ml-auto' onClick={()=>{setShowForm(false)}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M4 12L12 4M4 4L12 12" stroke="#1E293B" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                            </div>
                        </div> : connectNow === 2 ?
                        <>
                            <ConnectForm historyChats={historyChats} setShowFormLast={setShowFormLast} randomId={customerId ? customerId : randomId} conversationId={conversationId} formData={formData} id={id} setConnectNow={setConnectNow} setShowForm={setShowForm} />
                            {/* <ConnectForm setShowFormLast={setShowFormLast} randomId={randomId} conversationId={conversationId} formData={formData} id={id} setConnectNow={setConnectNow} setShowForm={setShowForm} /> */}
                        </>
                        : connectNow === 1 ?
                        <>
                            <div className="bg-purple-50 rounded-lg md:rounded-xl border border-purple-200 justify-between items-center flex md:px-4 py-2 px-3 md:py-4 gap-2 md:gap-4">
                                <div className='flex items-center gap-[15px] '>
                                    <div className='min-w-[28px]'>
                                        <Phone />
                                    </div>
                                    <div>
                                        <p className='text-slate-900 text-sm md:text-[15px] font-semibold leading-relaxed tracking-tight'>Want expert advice?</p>
                                        <p className='text-slate-900 text-sm font-normal leading-normal tracking-tight'>Get a callback from our expert team.</p>
                                    </div>
                                </div>
                                <button onClick={()=>{setConnectNow(2)}} className="justify-center h-[34px] px-4 md:px-5 py-[9px] transition-all duration-300 bg-[#e5384c] hover:bg-[#bf3e4d] rounded-md items-center gap-[7px] inline-flex">
                                    <span className="text-white text-sm md:text-[13px] font-semibold whitespace-nowrap">Get Help</span>
                                </button>
                            </div>
                            <div className='mt-7 relative z-[2]'>
                                <h3 className='text-slate-900 text-[15px] font-semibold mb-4'>Questions you might be thinking about</h3>
                                <div className='justify-start items-center flex-col flex w-full max-w-full border-t border-[#E2E8F0]'>
                                    {(resultQuestion?.length !== 0 ? resultQuestion : questionAi?.length !== 0 ? questionAi : questions).map((question, index)=>(
                                        <button onClick={() => handleMessage(question.question || question)} className="cursor-pointer justify-center items-center flex w-full" key={index}>
                                            <div className="grow shrink basis-0 self-stretch py-3 md:py-[14px] border-b border-[#E2E8F0] color-p w-full justify-between items-center gap-4 flex">
                                                <p className="self-stretch text-[#1E293B] text-sm font-medium flex items-start gap-1 text-left">
                                                    <span className='mr-1.5 text-base md:text-[17px] mt-[-1.5px]'>{question.emoji ? question.emoji : ''}</span>
                                                    <span className='leading-[1.5]'>
                                                     {question.question || question}
                                                    </span>
                                                </p>
                                                <svg className='min-w-[19px]' xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                                    <path d="M9.50024 4.76758V14.8926" stroke="#334155" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M4.43774 9.83008H14.5627" stroke="#334155" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </div>
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </> : ''
                    }
                </div> 
            }
        </div>
    );
};

export default Conversation;