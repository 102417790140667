import React, { useState, useEffect, useRef } from 'react';


const FormMessage = ({ promptLoading, data, handlePrompt }) => {
    const [inputValue1, setInputValue1] = useState('');
    return (
        <form className={`relative tta-input-form mt-auto p-[1px] rounded-[7px]`} onSubmit={(e) => {handlePrompt(e); setInputValue1('')}}>
            <input disabled={promptLoading} value={inputValue1} onChange={(e) => setInputValue1(e.target.value)} type='text' id='prompt' name='prompt' placeholder={data?.AI_message_placeholder ? data.AI_message_placeholder : 'Enter a prompt here'} className={`md:h-[52px] bg-white h-[42px] focus-visible:outline-[#8451D4] focus:outline-[#8451D4] active:outline-[#8451D4] w-full px-4 md:py-3 py-[6px] pr-14 rounded-md border border-slate-300 text-[#1E293B] text-sm font-normal leading-normal ${promptLoading && 'cursor-not-allowed'}`} />
            <button disabled={promptLoading} type='submit' className={`md:w-[30px] md:h-[30px] w-[24px] h-[24px] absolute rounded-[8px] right-3 md:top-3 top-[5.5px] ${promptLoading && 'cursor-not-allowed'}`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                    <rect x="0.0690918" y="0.72168" width="28" height="28" rx="8" fill={"#6A47F2"} />
                    <path d="M9.90039 14.0269L14.0685 9.85889M14.0685 9.85889L18.2365 14.0269M14.0685 9.85889L14.0685 19.5844" stroke="white" strokeWidth="1.39693" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
        </form>
    );
};

export default FormMessage;